import * as d3 from "d3";
import D3Version from "../../../src/pages/2019-02-16-draw-an-interactive-elevation-chart-with-d3-and-react-pt-2/D3Version";
import Chart from "../../../src/components/d3/Chart";
import data from "../../../src/components/d3/data";
import React from 'react';
import { MDXTag } from '@mdx-js/tag';
export default {
  d3,
  D3Version,
  Chart,
  data,
  React,
  MDXTag
};