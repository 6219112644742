module.exports = [{
      plugin: require('/codebuild/output/src632390377/src/blog/node_modules/gatsby-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"]},
    },{
      plugin: require('/codebuild/output/src632390377/src/blog/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src632390377/src/blog/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
