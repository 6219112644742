export const markerPositionsHI = [ { lat: 20.108041337744396, lng: -155.59666531849717 }, { lat: 20.108625679154304, lng: -155.5937685327611 }, { lat: 20.11104362041467, lng: -155.5942406015477 }, { lat: 20.11345983582809, lng: -155.5931969275535 }, { lat: 20.11400385906143, lng: -155.59081512594827 }, { lat: 20.115414280856378, lng: -155.58858352804788 }, { lat: 20.117674634503672, lng: -155.5855219656006 }, { lat: 20.117453000837656, lng: -155.58425596294558 }, { lat: 20.116022448711515, lng: -155.5850713544861 } ]

export const mapCenterHI = {
  lat: 20.116521838422806,
  lng: -155.590969384691
}

export const markerPositionsSF = [ { lat: 37.80202084444066, lng: -122.41955600757011 }, { lat: 37.80210111666835, lng: -122.41938048805349 }, { lat: 37.80205025343619, lng: -122.41929733957403 }, { lat: 37.8019909129544, lng: -122.41922223772161 }, { lat: 37.802060849945775, lng: -122.4191310426151 }, { lat: 37.802139264069496, lng: -122.41906935180776 }, { lat: 37.802110779926295, lng: -122.4189838410803 }, { lat: 37.80204578650493, lng: -122.4189061247899 }, { lat: 37.802066979523865, lng: -122.41881761189241 }, { lat: 37.80216234803381, lng: -122.41875860329408 }, { lat: 37.802172635385745, lng: -122.41867557849932 }, { lat: 37.80209915843709, lng: -122.41861060610567 }, { lat: 37.802083812143856, lng: -122.41853485728969 }, { lat: 37.80215401157957, lng: -122.41847048427333 }, { lat: 37.802221829119375, lng: -122.41837392474879 }, { lat: 37.80214523057462, lng: -122.41828445292184 }, { lat: 37.80212615687803, lng: -122.41819862223336 }, { lat: 37.80221092882507, lng: -122.41809938049981 } ]

export const mapCenterSF = {
  // Lombard Street, San Francisco
  lat: 37.80508261545093,
  lng: -122.40331937291076
}

export const mapCenterNY = {
  // Bedford-Stuyvesant, Brooklyn NY
  lat: 40.6872176,
  lng: -73.94177350000001
}

export const mapCenterUK = {
  // London
  lat: 51.50616621357263,
  lng: -0.12923947598137087
}

export const mapCenterESP = {
  // Madrid
  lat: 40.41823753663909,
  lng: -3.7147311000803462
}
export const mapCenterNZ = {
  lat: -45.85806843030581,
  lng: 170.51968535193052
}

// export const mapCenterCZ = { lat: 50.01390148979486, lng: 14.114177167006119 }
export const mapCenterCZ = {lat: 50.04608752561645, lng: 14.096449803888875}

export const markerPositionsNZ = [ { lat: -45.84830669087012, lng: 170.53305542533894 }, { lat: -45.848634159292786, lng: 170.53348563987493 }, { lat: -45.84896413053948, lng: 170.53392062933256 }, { lat: -45.84932386923649, lng: 170.53442954164962 }, { lat: -45.84971326028466, lng: 170.5349524277966 }, { lat: -45.85006223567713, lng: 170.53541608967237 } ]

export const markerPositionsCZ = [ { lat: 50.09516601165425, lng: 13.992390566304152 }, { lat: 50.09120141110008, lng: 14.067921572163527 }, { lat: 50.075780401747764, lng: 14.155812197163527 }, { lat: 50.0436006164076, lng: 14.21143048329634 }, { lat: 50.01103334687082, lng: 14.198311707080506 }, { lat: 50.033973697387836, lng: 14.112481018603944 }, { lat: 50.049849061480714, lng: 14.021043723762887 }, { lat: 50.01500457610805, lng: 13.963365501106637 }, { lat: 49.98168020353407, lng: 14.017313934178105 }, { lat: 49.971523817771256, lng: 14.090785003514043 }, { lat: 49.96357384653302, lng: 14.171122527928105 }, { lat: 49.98918571953353, lng: 14.244593597264043 } ]

export const mapCenterEverest = {lat: 28.0028199030931, lng: 86.90460577704815}
export const markerPositionsEverest = [ { lat: 28.012099984969584, lng: 86.8713263916984 }, { lat: 28.00664401162447, lng: 86.87922281503825 }, { lat: 28.002400285750564, lng: 86.88265604257731 }, { lat: 28.00058149492087, lng: 86.88883585214762 }, { lat: 27.996943821163455, lng: 86.88952249765543 }, { lat: 27.994518637108655, lng: 86.89913553476481 }, { lat: 27.994215485264824, lng: 86.90634531259684 }, { lat: 27.99201760889798, lng: 86.91106600046305 }, { lat: 27.990924161301606, lng: 86.91572679664091 }, { lat: 27.989408343010446, lng: 86.9168425955911 }, { lat: 27.989484134431216, lng: 86.92079080726103 }, { lat: 27.988498841805146, lng: 86.92388071204618 }, { lat: 27.986755609715367, lng: 86.92585481788115 } ]

// export const markerPositionsCZ = [
//   { lat: 50.052860026508064, lng: 14.371418886616652 },
//   { lat: 50.04845100639373, lng: 14.273915224507277 },
//   { lat: 50.01934131387633, lng: 14.197010927632277 },
//   { lat: 49.98094248142367, lng: 14.130406313374465 },
//   { lat: 49.96865314629551, lng: 14.074715515674256 },
//   { lat: 49.99911769001349, lng: 13.998497864307069 },
//   { lat: 50.026034046539564, lng: 13.921480125130074 },
//   { lat: 50.059107266157795, lng: 13.8569354473957 }
// ]
