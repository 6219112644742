import LoadMapsApi from "../../../src/components/maps/LoadMapsApi";
import MapWithMarkers from "../../../src/components/maps/MapWithMarkers";
import { markerPositionsHI, mapCenterHI, markerPositionsSF, mapCenterSF, mapCenterESP, markerPositionsNZ, mapCenterNZ } from "../../../src/components/maps/data";
import React from 'react';
import { MDXTag } from '@mdx-js/tag';
export default {
  LoadMapsApi,
  MapWithMarkers,
  markerPositionsHI,
  mapCenterHI,
  markerPositionsSF,
  mapCenterSF,
  mapCenterESP,
  markerPositionsNZ,
  mapCenterNZ,
  React,
  MDXTag
};